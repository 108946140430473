body {
  margin: 0;
  font-family: 'Avenir', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 400;
  src: local('../fonts/Avenir\ Medium/Avenir\ Medium.tff'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'Avenir';
  font-style: bold;
  font-weight: 400;
  src: local('../fonts/Avenir\ Black/Avenir\ Black.tff'); /* IE9 Compat Modes */
}

h1 {
  font-style: bold;
  /* color: #fff; */
  font-size: 72px;
  margin-top: 0px;
  margin-bottom: 20px;
}

h2 {
  font-weight: normal;
  font-size: 30px;
  margin-top: 0px;
}